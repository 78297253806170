import React, { useEffect, useState } from 'react';
import AgeCounter from './countdown';

interface AnimatedItem {
  id: number;
  left: string;
  animationDuration: string;
}

const BirthdayPage: React.FC = () => {
  const [confetti, setConfetti] = useState<AnimatedItem[]>([]);
  const [balloons, setBalloons] = useState<AnimatedItem[]>([]);

  useEffect(() => {
    const confettiInterval = setInterval(() => {
      setConfetti(prev => [...prev, {
        id: Date.now(),
        left: `${Math.random() * 100}%`,
        animationDuration: `${Math.random() * 3 + 2}s`
      }]);
    }, 500);

    const balloonInterval = setInterval(() => {
      setBalloons(prev => [...prev, {
        id: Date.now(),
        left: `${Math.random() * 100}%`,
        animationDuration: `${Math.random() * 5 + 3}s`
      }]);
    }, 3000);

    return () => {
      clearInterval(confettiInterval);
      clearInterval(balloonInterval);
    };
  }, []);

  useEffect(() => {
    if (confetti.length > 100) {
      setConfetti(prev => prev.slice(1));
    }
    if (balloons.length > 20) {
      setBalloons(prev => prev.slice(1));
    }
  }, [confetti, balloons]);

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>happy birthday</h1>
        <h1 style={styles.title}>adrianita ❤️</h1>
        <div style={styles.logoContainer}>
            <div style={styles.logoGlow}></div>
            <img src={process.env.PUBLIC_URL + '/adrisworld.png'} alt="Adri's World" style={styles.logoImage}/>
        </div>
        <AgeCounter 
          render={(age) => (
            <div style={styles.ageCounter}>
              <p>{age} years thrived</p>
            </div>
          )}
        />
        <div style={styles.ageCounter}>
          <blockquote style={{ fontStyle: 'italic', color: '#FFF', opacity: 0.95, fontSize: '0.9rem' }}>
            "I can and I will" - adriana o.
          </blockquote>
          <br />
          <p style={{ fontSize: '0.75rem', fontStyle: 'italic', opacity: 0.9 }}>thinking of you—always <br />i hope you're well<br /> with all my love ❤️</p>
          {/* <p>i love you. i hope you're well.</p> */}
        </div>
    </div>
      {confetti.map(item => (
        <div 
          key={item.id}
          style={{
            ...styles.confetti,
            left: item.left,
            animation: `fall ${item.animationDuration} linear forwards`
          }}
        />
      ))}
      {balloons.map(item => (
        <div 
          key={item.id}
          style={{
            ...styles.balloon,
            left: item.left,
            animation: `float ${item.animationDuration} ease-in-out forwards`
          }}
        >
          <img src={process.env.PUBLIC_URL + "/persimmon.png"} alt="persimmon" style={ {width: 200} }/>
        </div>
      ))}
      <style>
        {`
          @keyframes fall {
            0% { transform: translateY(-10px) rotate(0deg); opacity: 1; }
            100% { transform: translateY(100vh) rotate(360deg); opacity: 0; }
          }
          @keyframes float {
            0% { transform: translateY(0) rotate(0deg); opacity: 1; }
            100% { transform: translateY(-100vh) rotate(360deg); opacity: 0; }
          }
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
          }
          @keyframes rotate {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          @keyframes glow {
            0%, 100% { opacity: 0.75; transform: scale(1.05); }
            50% { opacity: 0.55; transform: scale(1.7); }
          }
        `}
      </style>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
    ageCounter: {
        fontSize: '1rem',
        color: 'white',
        fontWeight: 'bold',
        // textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        marginTop: '1rem',
        textAlign: 'center',
    },
    container: {
    height: '100vh',
    width: '100vw',
    background: 'linear-gradient(to bottom right, #9ebeb7, #1c4774)',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    textAlign: 'center',
    zIndex: 10,
  },
  title: {
    fontSize: '4rem',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: '1rem',
    animation: 'bounce 2s ease-in-out infinite',
  },
  logoContainer: {
    position: 'relative',
    width: '150px',
    height: '150px',
    margin: '2rem auto',
    animation: 'rotate 20s linear infinite',
  },
  logoGlow: {
    position: 'absolute',
    width: '100%',  // Match the container width
    height: '100%', // Match the container height
    borderRadius: '50%',
    background: 'radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 75%)',
    transform: 'translate(-50%, -50%)',
    animation: 'glow 3s ease-in-out infinite',
    zIndex: 1,  // Ensure glow is behind the image
  },
  logoImage: {
    width: '150px',
    height: 'auto',
    position: 'relative',
    zIndex: 2,
    borderRadius: '50%',
  },
  subtitle: {
    fontSize: '1.5rem',
    color: 'white',
  },
  confetti: {
    position: 'absolute',
    top: 0,
    width: '8px',
    height: '8px',
    background: 'white',
    borderRadius: '50%',
  },
  balloon: {
    position: 'absolute',
    bottom: 0,
    fontSize: '2.5rem',
  },
};

export default BirthdayPage;